<template>
  <div class="goTop" v-if="showButton" @click="scrollToTop"></div>
</template>
<script>
export default {
  // showButton: false,
  data() {
    return {
      showButton: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选，添加平滑滚动效果
      });
    },
    handleScroll() {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > 700) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.goTop {
  width: 30rem;
  height: 30rem;
  position: fixed;
  bottom: 20%;
  right: 20rem;
  display: flex;
  justify-content: center;
  z-index: 20;
  background: url("../../../assets/icon/goTop_icon.png");
  background-size: 100% 100%;
  cursor: pointer;
  border-radius: 100%;
}

.goTop:hover {
  background: url("../../../assets/icon/goTop_icon_high.png");
  background-size: 100% 100%;
}
</style>